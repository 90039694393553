import React from 'react';
import { css, injectGlobal } from 'emotion';
import _ from 'lodash';
import 'react-accessible-accordion/dist/minimal-example.css';
import Header from '../components/appComponents/Header';
import AccordionItem from '../components/appComponents/AccordionItem';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';

/*global tw*/
/*global google*/

injectGlobal(`.row{
    max-width: 82rem;
    @media (webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
        //Retina Specific
        max-width: 75rem;
    }
}`)

const styles = {
    listWrapper: css`
        ${tw`w-full bg-white overflow-y-scroll scrolling-touch`};
        border-radius: 16px 16px 0 0;
        height: 91.4511742891vh;
        // transform: translateY(-1vh);
        padding-bottom: 100px;
        box-shadow: 0 0 25px -2px rgba(0,0,0,0.3);
         -webkit-overflow-scrolling: touch;
    `,
    carTitle: css`
        ${tw`my-1`}
        opacity: 0.77;
        font-family: Chromatica-Bold;
        font-size: 25.89px;
        color: #222222;
        letter-spacing: -0.83px;
        padding-top: 0.45rem;

    `,
}

class faq extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            vendors: {}
        };
        this.accordionWrapper = null;
    }

    render() {
        return (
            <>
                <div className={css`${tw`w-screen max-w-screen max-h-screen overflow-hidden relative`}; background-image: linear-gradient(115deg, #AA0BCA 0%, #7D0EDE 39%)`}>
                    <Helmet
                        title='Hyderabad Rental Cars, Self Drive Cars in Hyderabad'
                        // title = "Self-Drive Car Rental In India : Outstation Cabs | Rent A Car | Car Hire Online | Outstation Taxi Booking Service | Airport Transfers | Book Hourly Rental Car - ZYPPYS"
                        meta={[
                            { name: 'description', content: 'Hiring self-drive car is in on your fingertip Zyppys is one of the best car rental services for outstation cabs, cab to airport and more. Zyppys is pocket and environment friendly.' },
                            // { name: 'description', content: 'Self Drive Car Rental In India - Outstation Cabs - Airport Transfer Services - Hourly Rental Car : Book best Self-drive car rentals online, hire Self drive cars without driver, Outstation cabs, Outstation taxi, Airport transfer services and Hourly rental cars at affordable prices at ZYPPYS in India.' },
                            { name: 'keywords', content: 'Zyypys, Car Rental, car rental near me, car rental app, car rental bangalore, car rental hyderabad, Roadtrip, Outstation Cabs, Road Travel, Chauffeur Driven Cars, Rental Cars, Cheap Rental Cars, Vacation Rentals, Outstation Rentals, Bike Rentals, Aha Taxis, Revv, Zoomcar, Avis, Hertz, Gozo Cabs, Savaari, Taxi Bazaar, Quick Ride, Car Pooling, Car sharing, Travel, Road travel, Airport Taxi, Self Drive Cars, Car Rentals, self drive car hire bangalore,self drive car rental bangalore ,self drive hire bangalore, self drive rental bangalore,self drive car hire hyderabad,self drive car rental hyderabad ,self drive hire hyderabad, self drive rental hyderabad,self drive hire cars ,self drive car rental ,self drive hire , self drive rental , car rental hyderabad, car hire hyderabad,car rental bangalore, car hire bangalore, Bangalore to Coorg car Rental, Bangalore to Mysore car Rental, Bangalore to Ooty car Rental, car rental, car hire, car rental near, outstation cabs, outstation car rental, outstation cabs one way, outstation cabs booking, car booking outstation, Bangalore to Chikmagalur cabs, outstation cabs hyderabad, outstation car rentals hyderabad, outstation cabs one way hyderabad, hyderabad to karimnagar cabs, bangalore to mysore cab, hyderabad to vijayawada cabs, hyderabad to warangal cabs,bangalore to coorg cab,bangalore to ooty cab, bangalore to nandi hills cab, cars, Bangalore to Pondicherry cabs, bangalore to tirupati cab, outstation cabs bangalore, outstation car rentals bangalore, cabs from hyderabad to srisailam, hyderabad to bangalore cab, Bangalore to Kodaikanal cabs, Bangalore to Chennai cabs, Bangalore to Wayanad cabs ' },
                            { name: "Viewport", content: "width=device-width" },
                            { property: "og:image", content: 'https://zyppysimages.s3.ap-south-1.amazonaws.com/zyplogo.png' },
                            { property: "og:image:width", content: "200" },
                            { property: "og:image:height", content: "200" },
                            { property: "og:type", content: "website" },
                            { property: "og:title", content: "Self Drive Cars & Rental Car, Car Rental, Cab on Rent" },
                            { property: "og:description", content: "Best car rental service for individual, family, corporate, self-drive cars and customize services for urban & semi-urban cities in India. Zyppys is a platform where people can compare and rent a car." },
                            // { property: "og:url", content: "https://zyppys.com/" },
                            { property: "og:site_name", content: "Zyppys" },
                            { property: "og:locale", content: "en_US" }
                        ]}
                    />
                    <Header />
                    <div className={styles.listWrapper}>
                        <div className={
                            css`${tw`w-full flex flex-wrap justify-between items-end px-10 lg:px-4 -mx-2 mb-6 lg:mx-auto`}; 
                            max-width: 82rem;
                            @media (webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
                                //Retina Specific
                                max-width: 75rem;
                            }`}>
                            <div className={css`${tw`w-full lg:w-1/2 py-2`}`}>
                                <h3 className={styles.carTitle}>Frequently Asked Questions</h3>
                            </div>
                        </div>
                        <div className={css`${tw`w-full rounded-md px-4`}` + ' row'} ref={node => this.accordionWrapper = node}>
                            <AccordionItem title="When will I get my security deposit back?"><p className={css`${tw`p-4`}`}>You will receive it in 5 to 12 working days.</p></AccordionItem>
                            <AccordionItem title="What do I need to rent a self drive car?"><p className={css`${tw`p-4`}`}>You must be at least 21 years old with a valid driving license &amp; license must be at least 1 year old, Valid ID (Aadhar or Passport), some companies will ask you for utility bill, student id for students and there may be additional documentation required for international drivers.</p></AccordionItem>
                            <AccordionItem title="Can I rent a car with fuel or without fuel?"><p className={css`${tw`p-4`}`}>Yes. Few car rental companies have both options - with &amp; without fuel, while few companies offer cars without fuel only.</p></AccordionItem>
                            <AccordionItem title="What payment methods are available?"><p className={css`${tw`p-4`}`}>You can pay using Net-banking, Wallets, Debit and Credit cards like Visa, Master &amp; Amex.</p></AccordionItem>
                            <AccordionItem title="Do I need insurance to rent a car?"><p className={css`${tw`p-4`}`}>No. Insurance is included in cost. If there are any damages, insurance will kick-in only after certain deductible amount which will be deducted from the security deposit.</p></AccordionItem>
                            <AccordionItem title="How do I change or cancel my reservation?"><p className={css`${tw`p-4`}`}>You can cancel using Zyppys app, but remember there could be cancellation charges. Please check the service provider's cancellation terms.</p></AccordionItem>
                            <AccordionItem title="Can I drive the car anywhere in India or do I need any permission?"><p className={css`${tw`p-4`}`}>Most of the cars will have an All India Permit.</p></AccordionItem>
                            <AccordionItem title="Any additional late returning charges?"><p className={css`${tw`p-4`}`}> Yes. Late returning charges will be applied according to rental car company policy.</p></AccordionItem>
                            <AccordionItem title="When will I get my security deposit?"><p className={css`${tw`p-4`}`}>Car rental companies will take at least 7 business days to refund security deposit. If  there are any damages, it could slightly get delayed to give you the correct damage cost.</p></AccordionItem>
                            <AccordionItem title="Do I need to pay the state border taxes?"><p className={css`${tw`p-4`}`}>Yes, border tax, toll gate charges, airport entry and any other challans you will be liable to pay.</p></AccordionItem>
                            <AccordionItem title="Any speeding limits on the vehicles?"><p className={css`${tw`p-4`}`}>Government mandated speed restrictions on all commercial cars. The restrictions could be from 80 -120 KMs.</p></AccordionItem>
                            <AccordionItem title="What's the policy on traffic violation challans?"><p className={css`${tw`p-4`}`}>You are responsible to clear the challans incurred during the rental period.</p></AccordionItem>
                            <AccordionItem title="What if I am traveling to India and want to rent a car?"><p className={css`${tw`p-4`}`}>For foreign nationals, international driving permit is required in addition to the driving license.</p></AccordionItem>
                            <AccordionItem title="Can someone else pick the vehicle on behalf of me?"><p className={css`${tw`p-4`}`}>No, only the person whose name is mentioned in the booking needs to show ID proofs (Drivers license, Addhar, Passport or Student Id) to pick the vehicle.</p></AccordionItem>
                            <AccordionItem title="What if the car gets stolen?"><p className={css`${tw`p-4`}`}>Immediately register a complaint at the nearest police station &amp; immediately inform the rental car company. Submit FIR copy to rental car company.</p></AccordionItem>
                            <AccordionItem title="What if the car gets a break down?"><p className={css`${tw`p-4`}`}>Call customer care team of the rental car company and the team will guide you through the process.</p></AccordionItem>
                        </div>
                    </div>
                </div>
            </>
        )
    }

}

const mapStateToProps = (state) => ({

})


export default connect(mapStateToProps)(faq);